import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import PaymentModal from "./Modal";

const FeeTableComponent = () => {
  const id = localStorage.getItem("userId");
  const [feeData, setFeeData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [ismodalOpen, setisModalOpen] = useState(false);
  const [currentFee, setCurrentFee] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [formData, setFormData] = useState({
    payment_method: "",
    payment_amount: "",
    month: "",
    student_id: id,
    fee_id: "",
  });
  const [approvalData, setApprovalData] = useState([]);
  const [requestModalOpen, setRequestModalOpen] = useState(false);

  // Fetch fee data on component mount
  useEffect(() => {
    axios
      .get(`https://api.erp.mindgrowthacademy.com/fee/${id}`)
      .then((response) => {
        const filteredData = response.data.filter((fee) => fee.fee_id !== 0);
        setFeeData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the fee data!", error);
      });
  }, [id]);

  // Fetch fee categories when modal opens
  const fetchCategories = () => {
    axios
      .get("https://api.erp.mindgrowthacademy.com/api/fee-categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
  };

  // Fetch subcategories when a category is selected
  const fetchSubcategories = (categoryId) => {
    axios
      .get(
        `https://api.erp.mindgrowthacademy.com/api/fee-subcategories?categoryId=${categoryId}`
      )
      .then((response) => {
        setSubcategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subcategories", error);
      });
  };

  // Open modal and fetch categories for new or existing payment
  const handleModalOpen = (fee = null) => {
    fetchCategories(); // Fetch categories when the modal opens
    setModalOpen(true);
    if (fee) {
      setCurrentFee(fee);

      setFormData({
        payment_method: "",
        payment_amount: "",
        month: "",

        student_id: id,
        fee_id: fee.fee_id,
      });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentFee({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const groupByFeeCategory = (data) => {
    const groupedData = {};
    data.forEach((fee) => {
      if (!groupedData[fee.fee_category]) {
        groupedData[fee.fee_category] = [];
      }
      groupedData[fee.fee_category].push(fee);
    });
    return groupedData;
  };

  const groupedFeeData = groupByFeeCategory(feeData);
  const studentInfo = feeData[0];

  const handleView = async (id) => {
    await axios
      .get(`https://api.erp.mindgrowthacademy.com/feeHistory/${id}`)
      .then((response) => {
        setPaymentDetails(response.data);
        setisModalOpen(true);
      })
      .catch((err) => {
        console.error("Error fetching fee history", err);
      });
  };

  const handleisModalClose = () => {
    setisModalOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare data for submission
    const data = {
      fee_id: parseInt(formData.fee_id),

      payment_method: formData.payment_method,
      payment_amount: parseInt(formData.payment_amount), // Convert to integer
      month: formData.month,

      student_id: parseInt(id), // Convert to integer
    };

    try {
      const response = await axios.post(
        "https://api.erp.mindgrowthacademy.com/api/paymentapproval",
        data
      );

      setFormData({
        payment_method: "",
        payment_amount: "",
        month: "",
        student_id: id,
        fee_id: "",
      });

      handleModalClose();
      window.location.reload();
      // Close the modal when payment is successful
      // Handle success (e.g., show a message, clear the form, etc.)
    } catch (error) {
      console.error("Error submitting payment:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleRequestFee = async (feeId) => {
    try {
      const response = await axios.get(
        `https://api.erp.mindgrowthacademy.com/api/paymentapproval/${feeId}`
      );

      setApprovalData(response.data);
      setRequestModalOpen(true);
    } catch (error) {
      console.error("Error fetching payment approval data:", error);
    }
  };
  return (
    <>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">Fee Details</h2>

        {/* Student Info Section */}
        {feeData.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xl font-bold">Student Information</h3>
            <p>
              <strong>Name:</strong> {studentInfo?.student_name}
            </p>
            <p>
              <strong>Class:</strong> {studentInfo?.student_class}
            </p>
            <p>
              <strong>Division:</strong> {studentInfo?.student_division}
            </p>
          </div>
        )}

        {/* Fee Categories and Payments */}
        {feeData.length > 0 &&
          Object.keys(groupedFeeData).map((feeCategory, idx) => (
            <div key={idx} className="mb-6">
              <h3 className="text-xl font-bold mb-2">{feeCategory} Fees</h3>
              <table className="min-w-full bg-white border border-gray-200 shadow-lg">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Subcategory</th>
                    <th className="py-3 px-6 text-left">Fee Amount</th>
                    <th className="py-3 px-6 text-left">Amount Paid</th>
                    <th className="py-3 px-6 text-left">Discount Amount</th>
                    <th className="py-3 px-6 text-left">Discount Reason</th>
                    <th className="py-3 px-6 text-left">Carry forward fee</th>
                    <th className="py-3 px-6 text-left">Amount Due</th>
                    <th className="py-3 px-6 text-left">Actions</th>
                    <th className="py-3 px-6 text-left">View</th>
                    <th className="py-3 px-6 text-left">Request Fee</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm">
                  {groupedFeeData[feeCategory].map((fee, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-6 text-left">
                        {fee.fee_subcategory}
                      </td>
                      <td className="py-3 px-6 text-left">₹{fee.fee_amount}</td>
                      <td className="py-3 px-6 text-left">
                        ₹{fee.amount_paid}
                      </td>
                      <td className="py-3 px-6 text-left">
                        ₹{fee.discount_amount || "N/A"}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {fee.discount_reason || "N/A"}
                      </td>
                      <td className="py-3 px-6 text-left">0.00</td>
                      <td className="py-3 px-6 text-left">
                        ₹{fee.amount_due_after_discount}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {console.log(fee.amount_due_after_discount)}
                        {fee.amount_due_after_discount == 0.0 ? (
                          <span className="text-green-600">Fee Paid</span>
                        ) : (
                          <button
                            className="bg-yellow-500 text-white py-1 px-3 rounded"
                            onClick={() => handleModalOpen(fee)}
                          >
                            Monthly Fee
                          </button>
                        )}
                      </td>

                      <td className="py-3 px-6 text-left">
                        <button
                          className="bg-blue-500 text-white py-1 px-3 rounded"
                          onClick={() => handleView(fee.fee_id)}
                        >
                          View
                        </button>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <button
                          className="bg-blue-500 text-white py-1 px-3 rounded"
                          onClick={() => handleRequestFee(fee.fee_id)}
                        >
                          Request Fee
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

        {/* Payment Modal */}
        {modalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
              <h3 className="text-lg font-semibold mb-4">Monthly Payment</h3>
              <form onSubmit={handleSubmit}>
                {/* Student ID */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Student ID
                  </label>
                  <input
                    type="number"
                    name="student_id"
                    value={formData.student_id}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    disabled
                  />
                </div>

                {/* Fee Amount */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Monthly Fee
                  </label>
                  <input
                    type="number"
                    name="fee_amount"
                    value={currentFee.fee_amount / 12 || 0}
                    className="w-full p-2 border border-gray-300 rounded"
                    disabled
                  />
                </div>

                {/* Mode of Payment */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Mode of Payment
                  </label>
                  <input
                    type="text"
                    name="payment_method"
                    value={formData.payment_method}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="e.g., Cash, Card"
                  />
                </div>

                {/* Amount Paid */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Amount Paid
                  </label>
                  <input
                    type="number"
                    name="payment_amount"
                    value={formData.payment_amount}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="Enter Amount"
                    required
                  />
                </div>

                {/* Month */}
                {/* Month Selection */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Month
                  </label>
                  <select
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  >
                    <option value="" disabled>
                      Select Month
                    </option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>

                {/* Discount Amount */}

                {/* Discount Reason */}

                <div className="flex justify-between">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-green-500 text-white py-1 px-3 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Payment History Modal */}
        {ismodalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
              <h3 className="text-lg font-semibold mb-4">Payment History</h3>
              <table className="min-w-full bg-white border border-gray-200 shadow-lg">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Date</th>
                    <th className="py-3 px-6 text-left">Payment</th>
                    <th className="py-3 px-6 text-left">Amount Paid</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm">
                  {paymentDetails.map((payment, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-6 text-left">
                        {new Date(payment.payment_date).toLocaleDateString()}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {payment.payment_method}
                      </td>
                      <td className="py-3 px-6 text-left">
                        ₹{payment.payment_amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-red-500 text-white py-1 px-3 rounded"
                  onClick={handleisModalClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <PaymentModal
          payment={approvalData}
          isOpen={requestModalOpen}
          onClose={() => setRequestModalOpen(false)}
        />
      </div>
    </>
  );
};

export default FeeTableComponent;
