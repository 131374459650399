import Sidebar from "./Sidebar";
import Header from "./Header";

import { useState } from "react";
import Attendance from "./Attendance";
import FeeDetails from "./FeeDetails";
import Certificate from "./CertificateRequest";

import Profile from "../component/Profile";
import Dashboard from "./Dashborad";
const DashboardLayout = ({ onLogout }) => {
  const [section, setSection] = useState("dashboard"); // default section

  const renderSection = () => {
    switch (section) {
      case "attendance":
        return <Attendance />;
      case "feeStatus":
        return <FeeDetails />;
      case "profile":
        return <Profile />;
      case "Certificate":
        return <Certificate />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar setSection={setSection} />
      <div className="flex flex-col flex-grow">
        <Header onLogout={onLogout} />
        <main className="p-6 bg-gray-100 flex-grow">{renderSection()}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
