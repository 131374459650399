import React, { useState } from "react";
import axios from "axios";

const Login = ({ onLogin }) => {
  const [value, setValue] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const res = await axios.post(
        "https://api.erp.mindgrowthacademy.com/student/login",
        value
      );

      if (res.status === 200) {
        // Assuming the response includes token and user data
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userName", res.data.user.name);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("userId", res.data.user.id);

        // Set authentication state
        localStorage.setItem("isAuthenticated", "true"); // Save login status
        onLogin(); // Call onLogin to update app state

        // Redirect to dashboard
      } else {
        setError(res.data.Message || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error); // Debugging
      setError("An error occurred during login");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Login
        </h1>
        <form onSubmit={handleLogin} className="space-y-4">
          <input
            type="text"
            placeholder="Email"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            onChange={(e) => setValue({ ...value, email: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            onChange={(e) => setValue({ ...value, password: e.target.value })}
            required
          />
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          <button
            type="submit"
            className="w-full p-3 bg-blue-500 text-white rounded-md hover:bg-green-600 transition duration-300"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
