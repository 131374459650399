import React from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Call the onLogout function passed as a prop
    onLogout();
    // Redirect to the login page
    navigate("/login");
  };

  return (
    <header className="bg-white shadow p-4 flex justify-between items-center">
      <h1 className="text-2xl font-semibold">Welcome, Student!</h1>
      <div className="flex items-center">
        <button
          onClick={handleLogout}
          className="text-red-600 hover:text-red-800 transition duration-300"
        >
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
