import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  // Mock data for cards and table
  const summaryData = [
    { title: "Total Users", value: "1,245", increase: "5%" },
    { title: "Revenue", value: "$14,500", increase: "3%" },
    { title: "New Orders", value: "345", increase: "8%" },
    { title: "Pending Issues", value: "12", increase: "2%" },
  ];

  const recentActivities = [
    { id: 1, name: "User Signup", date: "Oct 25, 2024", status: "Completed" },
    { id: 2, name: "Order #2345", date: "Oct 24, 2024", status: "Processing" },
    {
      id: 3,
      name: "Revenue Update",
      date: "Oct 23, 2024",
      status: "Completed",
    },
  ];

  // Chart data
  const lineData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Visitors",
        data: [120, 200, 150, 180, 230, 210, 250],
        borderColor: "#4f46e5",
        backgroundColor: "rgba(79, 70, 229, 0.3)",
        tension: 0.3,
        fill: true,
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Header */}
      <header className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Dashboard</h1>
        <button className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700">
          Generate Report
        </button>
      </header>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {summaryData.map((item, index) => (
          <div
            key={index}
            className="bg-white shadow rounded-lg p-5 flex flex-col items-start"
          >
            <h3 className="text-gray-700 font-semibold text-lg">
              {item.title}
            </h3>
            <p className="text-2xl font-bold text-gray-900">{item.value}</p>
            <p className="text-sm text-green-500">+{item.increase} this week</p>
          </div>
        ))}
      </div>

      {/* Chart and Table Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Chart */}
        <div className="lg:col-span-2 bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Visitors Overview
          </h2>
        </div>

        {/* Recent Activities */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Recent Activities
          </h2>
          <ul>
            {recentActivities.map((activity) => (
              <li
                key={activity.id}
                className="flex justify-between items-center py-3 border-b"
              >
                <span className="text-gray-700">{activity.name}</span>
                <span className="text-sm text-gray-500">{activity.date}</span>
                <span
                  className={`text-sm ${
                    activity.status === "Completed"
                      ? "text-green-500"
                      : "text-yellow-500"
                  }`}
                >
                  {activity.status}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
