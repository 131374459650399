const Sidebar = ({ setSection }) => {
  return (
    <div className="w-64 bg-blue-700 text-white h-screen p-4">
      <h2 className="text-xl font-semibold mb-4">Student Dashboard</h2>
      <ul>
        <li
          onClick={() => setSection("dashboard")}
          className="p-2 hover:bg-blue-500 cursor-pointer"
        >
          Dashboard
        </li>
        <li
          onClick={() => setSection("attendance")}
          className="p-2 hover:bg-blue-500 cursor-pointer"
        >
          Attendance
        </li>
        <li
          onClick={() => setSection("feeStatus")}
          className="p-2 hover:bg-blue-500 cursor-pointer"
        >
          Fee Status
        </li>
        <li
          onClick={() => setSection("profile")}
          className="p-2 hover:bg-blue-500 cursor-pointer"
        >
          Profile
        </li>
        <li
          onClick={() => setSection("Certificate")}
          className="p-2 hover:bg-blue-500 cursor-pointer"
        >
          Certificate
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
