import React from "react";

const PaymentModal = ({ payment, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center ">
      <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-800">
            Payment Details
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="overflow-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead>
              <tr className="bg-gray-100 text-gray-700 uppercase text-xs">
                <th className="py-2 px-4">Student Name</th>
                <th className="py-2 px-4">Student ID</th>
                <th className="py-2 px-4">Payment Amount</th>
                <th className="py-2 px-4">Payment Date</th>
                <th className="py-2 px-4">Payment Method</th>
                <th className="py-2 px-4">Payment Status</th>
                <th className="py-2 px-4">Fee ID</th>
                <th className="py-2 px-4">Fee Amount</th>
                <th className="py-2 px-4">Payment Month</th>
              </tr>
            </thead>
            <tbody>
              {payment.map((payment, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                >
                  <td className="py-2 px-4">{payment.student_name}</td>
                  <td className="py-2 px-4">{payment.student_id}</td>
                  <td className="py-2 px-4">{payment.payment_amount}</td>
                  <td className="py-2 px-4">{payment.payment_date}</td>
                  <td className="py-2 px-4 capitalize">
                    {payment.payment_method}
                  </td>
                  <td className="py-2 px-4 capitalize">
                    {payment.payment_status}
                  </td>
                  <td className="py-2 px-4">{payment.fee_id}</td>
                  <td className="py-2 px-4">{payment.fee_amount}</td>
                  <td className="py-2 px-4">{payment.payment_month}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
