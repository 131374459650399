import { useState, useEffect } from "react";

const AttendanceDetails = () => {
  const [attendanceData, setAttendanceData] = useState(null);
  const [error, setError] = useState(null);

  // Fetch attendance data from the API using the student ID from localStorage
  useEffect(() => {
    const studentId = localStorage.getItem("userId"); // Retrieve student ID
    if (studentId) {
      fetchAttendanceDetails(studentId);
    }
  }, []);

  const fetchAttendanceDetails = async (studentId) => {
    try {
      const response = await fetch(
        `https://api.erp.mindgrowthacademy.com/api/attendance/getattendance/${studentId}`
      );
      const data = await response.json();
      console.log(data);
      setAttendanceData(data.attendance);
    } catch (error) {
      console.error("Error fetching attendance details:", error);
      setError("Could not load attendance data. Please try again later.");
    }
  };

  // Calculate the number of days present and absent
  const calculateDaysPresent = () =>
    attendanceData.filter((record) => record.status === "Present").length;
  const calculateDaysAbsent = () =>
    attendanceData.filter((record) => record.status === "Absent").length;

  // Calculate the attendance percentage
  const calculateAttendancePercentage = () => {
    const totalDays = attendanceData.length;
    const presentDays = calculateDaysPresent();
    return ((presentDays / totalDays) * 100).toFixed(2);
  };

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div className="p-4 bg-white rounded shadow">
      <h2 className="text-2xl font-semibold text-indigo-700 mb-4">
        Attendance Details
      </h2>

      {attendanceData ? (
        <>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-indigo-100 text-indigo-700">
                <th className="py-2 px-4 border">Date</th>
                <th className="py-2 px-4 border">Status</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData.map((record) => (
                <tr key={record.id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border text-center">
                    {record.date}
                  </td>
                  <td
                    className={`py-2 px-4 border text-center ${
                      record.status === "Present"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {record.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-4">
            <h3 className="text-lg font-semibold">Attendance Summary</h3>
            <p>
              <strong>Total Days Present:</strong> {calculateDaysPresent()}
            </p>
            <p>
              <strong>Total Days Absent:</strong> {calculateDaysAbsent()}
            </p>
            <p>
              <strong>Attendance Percentage:</strong>{" "}
              {calculateAttendancePercentage()}%
            </p>
          </div>
        </>
      ) : (
        <p>Loading attendance data...</p>
      )}
    </div>
  );
};

export default AttendanceDetails;
