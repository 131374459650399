import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function StudentProfile() {
  const id = localStorage.getItem("userId");
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `https://api.erp.mindgrowthacademy.com/newstudent/${id}`
        );
        setStudentData(response.data.student);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error("Error fetching student data:", err);
        setError("There was an error fetching the student data.");
      }
    };

    fetchStudentData();
  }, [id]);

  if (error) return <p className="text-center text-red-500">{error}</p>;
  if (!studentData) return <p className="text-center">Loading...</p>;

  const ProfileField = ({ label, value }) => (
    <div className="border border-gray-200 rounded-lg p-5 shadow-lg bg-white">
      <h3 className="font-semibold text-indigo-600">{label}</h3>
      <p className="text-gray-800 mt-1">{value || "-"}</p>
    </div>
  );

  const {
    firstName = "-",
    lastName = "-",
    class_id = "-",
    division_id = "-",
    busRoute = "-",
    identificationMark1 = "-",
    identificationMark2 = "-",
    dobPlace = "-",
    religion = "-",
    emailId = "-",
    mobileNo = "-",
    nationality = "-",
    address = {},
    admDate = "-",
    fatherName = "-",
    motherName = "-",
    alternateMobileNo = "-",
    uidNo = "-",
    regdNo = "-",
    category = "-",
    bloodGrp = "-",
  } = studentData;

  const {
    presentAddress = "-",
    permanentAddress = "-",
    city = "-",
    district = "-",
    state = "-",
    pincode = "-",
    country = "-",
    taluka = "-",
  } = address || {};

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-indigo-600">
      <div className="max-w-5xl mx-auto py-10 px-6 sm:px-12">
        <div className="text-center mb-12">
          <h2 className="text-white text-4xl font-semibold">Student Profile</h2>
          <p className="text-indigo-200 mt-2">
            Detailed student information at a glance
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ProfileField label="Name" value={`${firstName} ${lastName}`} />
          <ProfileField label="Registration No." value={regdNo} />
          <ProfileField
            label="Admission Date"
            value={admDate ? new Date(admDate).toLocaleDateString() : "-"}
          />
          <ProfileField label="Class" value={class_id} />
          <ProfileField label="Division" value={division_id} />
          <ProfileField label="Bus Route" value={busRoute} />
          <ProfileField
            label="Date of Birth"
            value={
              studentData.dob
                ? new Date(studentData.dob).toLocaleDateString()
                : "-"
            }
          />
          <ProfileField label="Place of Birth" value={dobPlace} />
          <ProfileField
            label="Identification Mark 1"
            value={identificationMark1}
          />
          <ProfileField
            label="Identification Mark 2"
            value={identificationMark2}
          />
          <ProfileField label="Religion" value={religion} />
          <ProfileField label="Email" value={emailId} />
          <ProfileField label="Mobile No." value={mobileNo} />
          <ProfileField
            label="Alternate Mobile No."
            value={alternateMobileNo}
          />
          <ProfileField label="Nationality" value={nationality} />
          <ProfileField label="Father's Name" value={fatherName} />
          <ProfileField label="Mother's Name" value={motherName} />
          <ProfileField label="UID No." value={uidNo} />
          <ProfileField label="Blood Group" value={bloodGrp} />

          <div className="border border-gray-200 rounded-lg p-5 shadow-lg bg-white col-span-full">
            <h3 className="font-semibold text-indigo-600 mb-2">Address</h3>
            <p className="text-gray-800">
              <strong>Present:</strong>{" "}
              {`${presentAddress}, ${city}, ${district}, ${state}, ${country}, ${pincode}`}
            </p>
            <p className="text-gray-800 mt-1">
              <strong>Permanent:</strong> {permanentAddress}
            </p>
            <p className="text-gray-800 mt-1">
              <strong>Taluka:</strong> {taluka}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
